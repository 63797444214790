import {Component} from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../_services';
import _ from 'lodash';

@Component({
  templateUrl: 'ratings.distribution.component.html',
  styleUrls: ['../admin.component.scss']
})
export class RatingsDistributionComponent {

  date: NgbDateStruct;
  gridStyle = {
    height: '210px',
    width: '560px'
  };
  rowData: any;
  columnDefs = [{
    headerName: '',
    children: [
      {headerName: '', field: 'label', width: 150},
    ]
  }, {
    headerName: 'All Covered Companies',
    children: [
      {headerName: '', field: 'cc1', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
      {headerName: '', field: 'cc2', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
    ]
  }, {
    headerName: 'Investment Banking Clients',
    children: [
      {headerName: '', field: 'ibc1', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
      {headerName: '', field: 'ibc2', width: 100, editable: true, cellEditor: 'agTextCellEditor'},
    ]
  }];
  gridApi: any;
  gridColumnApi: any;
  gridOptions: any;

  constructor(private calendar: NgbCalendar, private apiService: ApiService,
              private ngbDateParserFormatter: NgbDateParserFormatter) {
    const self = this;
    this.date = this.calendar.getToday();
    this.gridOptions = {
      stopEditingWhenGridLosesFocus: true
    };
    this.apiService.ratingsDistribution('data', {}).subscribe(response => {
      const date = response.ratingDate.split('-').map(d => Number(d));
      this.date = new NgbDate(date[0], date[1], date[2]);
      this.rowData = _.sortBy(response.ratingDistributions, ['order']);
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 320;
    /*Get the HOT length*/
    const hotData = this.rowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * 28) + 80;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  updateRatings(): void {
    this.apiService.ratingsDistribution('update', {
      ratingDate: _.reduce(this.date, (result, val) => result += (result ? '-' : '') + val, ''),
      ratingDistributions: this.rowData
    }).subscribe();
  }


}
