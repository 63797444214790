export let panels = [
  // Add User Administration Panel.
  {
    icon: 'fa-users',
    iconUrl: '/users/allUser',
    name: 'User',
    description: 'Admin page for user administration',
    options: [
      {optionName: 'Create New User', optionUrl: '/users/newUser', enabled: true},
      {optionName: 'View All Users', optionUrl: '/users/allUser', enabled: true}
    ]
  },
  // Add Issuer Administration Panel.
  {
    icon: 'fa-registered',
    iconUrl: '/issuers/allIssuer',
    name: 'Issuer',
    description: 'Admin page for issuer administration',
    options: [
      {optionName: 'Create New Issuer', optionUrl: '/issuers/newIssuer', enabled: true},
      {optionName: 'View All Issuers', optionUrl: '/issuers/allIssuer', enabled: true}
    ]
  },
  // Add Distributors Administration Panel
  {
    icon: 'fa-suitcase',
    iconUrl: '/distributors/allDistributor',
    name: 'Distributors',
    description: 'Admin page for distributors administration',
    options: [
      {
        optionName: 'Create New Distributor',
        optionUrl: '/distributors/newDistributor',
        enabled: false
      },
      {
        optionName: 'View All Distributors',
        optionUrl: '/distributors/allDistributor',
        enabled: true
      }
    ]
  },
  // Add Industries Administration Panel
  {
    icon: 'fa-steam-square',
    iconUrl: '/industries/allIndustries',
    name: 'Industries',
    description: 'Admin page for industries administration',
    options: [
      {
        optionName: 'Create New Industry',
        optionUrl: '/industries/newIndustry',
        enabled: true
      },
      {
        optionName: 'View All Industries',
        optionUrl: '/industries/allIndustries',
        enabled: true
      }
    ]
  },
  // Add Panel for creating new documents
  {
    icon: 'fa-file-text-o',
    iconUrl: '/home',
    name: 'New Documents',
    description: 'This page for creating company reports',
    options: [
      {
        optionName: 'Company Report',
        optionUrl: '/companyreport/create',
        enabled: true
      },
      {
        optionName: 'Flash Note',
        optionUrl: '/flashnote/create',
        enabled: true
      }
    ]
  },
  // Add Other Documents Panel
  {
    icon: 'fa-file-text-o',
    iconUrl: '/home',
    name: 'Other Documents',
    description: 'This page for creating other new documents',
    options: [
      // {
      //   optionName: 'Industry Report',
      //   optionUrl: '/industryreport/create',
      //   enabled: true
      // },
      {
        optionName: 'Morning Call',
        optionUrl: '/newdoc/morningcall',
        enabled: true
      }
    ]
  },
  {
    icon: 'fa-file-text-o',
    iconUrl: '/home',
    name: 'Industry Documents',
    description: 'This page for creating other new documents',
    options: [
      {
        optionName: 'Industry Report',
        optionUrl: '/industryreport/create',
        enabled: true
      },
      {
        optionName: 'Industry Change Report',
        optionUrl: '/industryChangeReport/create',
        enabled: true
      },
      {
        optionName: 'Industry Note EDM',
        optionUrl: '/industryNoteEdm/create',
        enabled: true
      },
      {
        optionName: 'Industry Spotlight',
        optionUrl: '/industrySpotlight/create',
        enabled: true
      }
    ]
  },
  // View Panel for Rating Distributions
  {
    icon: 'fa-percent',
    iconUrl: '/distributors/ratings',
    name: 'Ratings Distribution',
    description: 'Admin page for rating distribution',
    options: [
      {
        optionName: 'View',
        optionUrl: '/distributors/ratings',
        enabled: true
      }
    ]
  },
  // {
  //   icon: 'fa-file-word-o',
  //   iconUrl: '/home',
  //   name: 'Word Add-In Installer',
  //   description: 'Link to download Microsoft Word Add-in installer',
  //   options: [
  //     {
  //       optionName: 'Download',
  //       optionType: 'WordAddin',
  //       optionUrl: '/home',
  //       enabled: true
  //     }
  //   ]
  // },
  // Panel to download installer
  {
    icon: 'fa-download',
    iconUrl: '/home',
    name: 'Add-in installer',
    description: 'Link to download Add-in installer',
    options: [
      {
        optionName: 'Word Add-in',
        optionType: 'WordAddin',
        optionUrl: '/home',
        enabled: true
      },
      {
        optionName: 'Blank Workbook',
        optionType: 'BlankWorkbook',
        optionUrl: '/home',
        enabled: true
      },
      {
        optionName: 'Excel Add-in',
        optionType: 'ExcelAddin',
        optionUrl: '/home',
        enabled: true
      }
    ]
  },
  // Filler Card to align cards
  // {
  //   icon: 'filler',
  //   iconUrl: 'filler',
  //   name: 'filler',
  //   description: 'filler',
  //   options: []
  // },
];
