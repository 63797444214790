import { Component, OnInit } from "@angular/core";
import { AlertService, ApiService, AuthenticationService, DataService } from "../_services";
import { DocumentModel } from "./document.model";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver/dist/FileSaver";
import { getRequestDataModel } from "./requestDataModel";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-industry-note-tmt-report",
  templateUrl: "./industryNoteTmtReport.component.html",
  styleUrls: ["./document.component.scss"],
})
export class IndustryNoteTmtReportComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  selectedTab: String;
  comments: string;
  loggedInUserName: string;
  checkInDocument: any;
  checkInDocumentPath: string;

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.route.params.subscribe((params) => (this.params = params));
    this.checkInDocumentPath = "Choose file";
    this.selectedTab = "Metadata";
  }

  ngOnInit(): void {
    this.authService.user.subscribe((user) => (this.loggedInUserName = user.displayName));
    if (this.params.action === "create") {
      this.apiService.createDocument({ productType: "Industry Note - EDM" }).subscribe((data) => (this.formModel.actionNote.productID = Number(data)));
    } else {
      if (!this.dataService.selectedStage || !this.dataService.openDocumentData) {
        this.router.navigate(["/documents"]);
      } else {
        const requestBody = {
          productType: this.dataService.openDocumentData.productName,
          versionID: 1,
          stageName: this.dataService.selectedStage,
          productID: this.dataService.openDocumentData.productID,
        };
        this.apiService.openDocument(requestBody).subscribe((data) => {
          this.setInitialData(data);
        });
      }
    }
    this.apiService.getPrimaryIndustries().subscribe((data) => (this.formModel.primaryIndustries = data));
    this.apiService.getAnalysts().subscribe((data) => (this.formModel.primaryAuthors = data));
    this.apiService.getSecondaryIssuers().subscribe((data) => (this.formModel.secondaryIssuers = data));
  }

  enableTab(tab): boolean {
    return !(tab === "distribution" && this.dataService.selectedStage === "Production");
  }

  createDocument(event): void {
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      versionID: 1,
      industry: event,
      stageName: "",
    };
    this.apiService.createSpecifiedDocument("industrynoteedm", requestBody).subscribe((data) => {
      this.setInitialData(data);
    });
  }

  setInitialData(data) {
    this.formModel.actionNote = data.actionNote;
    this.formModel.selectedPrimaryIndustry = this.formModel.actionNote.metadata.primaryIndustry[0].name;
    this.formEnabled = true;
    this.formModel.actionNote.distributionRecipient.forEach((value) => (value.prevSelected = value.selected));
  }

  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case "Metadata":
          this.onSave();
          break;
        case "FinancialMatrix":
          break;
        case "Comments":
          this.onAddComment();
          break;
        case "AuthorDocument":
          break;
        case "DistributionRecipients":
          this.saveDistribution();
          break;
        default:
          break;
      }
    }

    this.selectedTab = tab;
  }

  abstractHTMLChanged(event) {
    this.formModel.actionNote.metadata.abstract = event.text;
  }

  openAbstractHTML() {
    const requestBody = {
      tagName: null,
      text: this.formModel.actionNote.metadata.htmlText,
    };
    this.apiService.previewHTmlBody(requestBody).subscribe((response) => {
      const newWindow = window.open();
      newWindow.document.write(response.text);
      newWindow.document.close();
    });
  }

  onClose() {
    this.apiService
      .closeDocument({
        productType: this.formModel.actionNote.productName,
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: this.formModel.actionNote.productID,
      })
      .subscribe((data) => this.router.navigate(["/documents"]));
  }

  checkSave(content) {
    if(this.formModel.actionNote.metadata.secondaryIssuer.length < 1 && this.dataService.selectedStage === 'Authoring'){
      this.modalService.open(content, {centered: true});

    }
    else{

    this.onSave()
    
    }
  }
  onSave(){
    this.apiService.updateDocument("industrynoteedm", getRequestDataModel(this.formModel, false)).subscribe((data) => {});
  }
  getComment(): string {
    const timeStamp = new Date();
    const previousComments = this.formModel.actionNote.comments || "";
    const currentComments = this.comments ? `<br /><br />${this.loggedInUserName} ${timeStamp.toString()}:<br />${this.comments}` : "";
    const comment = `${previousComments} ${currentComments}`;
    this.formModel.actionNote.comments = comment;
    return comment;
  }

  onAddComment() {
    this.apiService
      .saveDocumentComments("industrynoteedm", {
        productID: this.formModel.actionNote.productID,
        VersionID: 1,
        Comments: this.getComment(),
      })
      .subscribe((data) => (this.comments = ""));
  }

  getBlankTemplate(): void {
    this.apiService.downloadBlankTemplate(this.formModel.actionNote.productName, this.formModel.actionNote.docID).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  checkOutDocument(): void {
    this.apiService.checkoutDocument(this.formModel.actionNote.productName, "workflow", this.formModel.actionNote.docID, this.dataService.selectedStage).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  saveDistribution() {
    const requestBody = {
      versionID: 1,
      distributionGroup: this.formModel.actionNote.distributionGroup,
      productID: this.formModel.actionNote.productID,
      distributionRecipient: this.formModel.actionNote.distributionRecipient,
    };

    this.apiService.saveDistribution(requestBody).subscribe();
  }

  checkDistributionRecipients(data) {
    this.formModel.actionNote.distributionRecipient.forEach((value) => {
      if (value.name.includes("Limited") || value.name.includes("Unlimited")) {
        value.selected = false;
      }
      if (value.name.includes(data)) {
        value.selected = true;
      }
    });
  }

  checkDisabled(data) {
    if (data.name.includes("Limited") || data.name.includes("Unlimited")) {
      return !data.name.includes(this.formModel.actionNote.distributionGroup);
    } else {
      return !data.prevSelected;
    }
  }

  // onFileChange(event, fileInput): void {
  //   if (`${this.formModel.actionNote.docID}.docx` === fileInput.files[0].name || `${this.formModel.actionNote.docID}.doc` === fileInput.files[0].name) {
  //     this.checkInDocument = fileInput.files[0];
  //     this.checkInDocumentPath = fileInput.files[0].name;
  //   } else {
  //     this.alertService.error("Please upload a valid document");
  //   }
  //   fileInput.value = null;
  // }


  onFileChange(event, fileInput): void {
    const file = fileInput.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    const validExtensions = ['doc', 'docx'];
  
    if (validExtensions.includes(fileExtension)) {
      this.checkInDocument = file;
      this.checkInDocumentPath = fileName;
    } else {
      this.alertService.error("Please upload a valid document (.doc or .docx)");
    }
  
    fileInput.value = null;
  }
  

  checkIn(): void {
    const formData = new FormData();
    formData.append("uploadDocument", this.checkInDocument);
    formData.append("actionDestination", "workflow");
    formData.append("actionFileName", this.checkInDocumentPath);
    formData.append("productID", `${this.formModel.actionNote.productID}`);
    formData.append("loginName", this.authService.user.value.loginName);
    this.apiService.checkinDocument(formData).subscribe((response) => {
      this.checkInDocument = null;
      this.formModel.actionNote.metadata.htmlText = response.htmlText;
      this.checkInDocumentPath = "Choose file";
    });
  }

  getAbstrachHTML(){
    const productID = this.formModel.actionNote.productID
    this.apiService.getAbstractHtml(productID).subscribe((res)=>{
      this.formModel.actionNote.metadata.htmlText = res;
    })
  }
}
