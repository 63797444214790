export function getRequestDataModel(data: any, isCompanyReport: boolean): any {
  let primaryIssuer: any;
  primaryIssuer = data.actionNote.primaryIssuer;
  if (isCompanyReport) {
    return {
      metadata: data.actionNote.metadata,
      siteVisit: '',
      investmentRisk: primaryIssuer.investmentRisk,
      companyDescription: primaryIssuer.companyDescription,
      valuationMethodology: primaryIssuer.valuationMethodology,
      sectorRecommendation: primaryIssuer.sectorRecommendation,
      adrExchange: '',
      adrTargetPrice: '',
      adrTicker: '',
      currency: primaryIssuer.currency,
      currentPrice: primaryIssuer.currentPrice,
      estimateAction: '',
      flashCurrentPrice: '',
      flashSharesIssues: '',
      previousRecommendation: '',
      previousTargetPrice: '',
      productID: data.actionNote.productID,
      productType: data.actionNote.productName,
      recommendation: primaryIssuer.recommendation,
      recommendationAction: primaryIssuer.recommendationAction,
      targetPrice: primaryIssuer.targetPrice,
      targetPriceAction: primaryIssuer.targetPriceAction,
      versionID: '1'
    };

  } else {
    return {
      'adrExchange': '',
      'adrTargetPrice': '',
      'adrTicker': '',
      'currency': '',
      'estimateAction': '',
      'flashCurrentPrice': '',
      'flashSharesIssues': '',
      'previousRecommendation': '',
      'previousTargetPrice': '',
      'productID': data.actionNote.productID,
      'productType': data.actionNote.productName,
      'recommendation': '',
      'recommendationAction': '',
      'targetPrice': '',
      'targetPriceAction': '',
      'versionID': '1',
      'metadata': data.actionNote.metadata,
      'SiteVisit': null,
      'eps_Ebita': data.actionNote.productIndustryDetails.eps_EBITA,
      'InvestmentRisk': data.actionNote.productIndustryDetails.investmentRisk,
      'CompanyDescription': null,
      'ValuationMethodology': data.actionNote.productIndustryDetails.valuationMethodology,
      'SectorRecommendation': null,
      'startYear': data.actionNote.productIndustryDetails.startYear,
      'unit' : data.actionNote.productIndustryDetails.unit
    };
  }
}
