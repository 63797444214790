export let panels = [
  // Add User Administration Panel.
  {
    icon: 'fa-sitemap',
    iconUrl: '/reports',
    name: 'Workflow History',
    description: 'Click on the link below to download the Workflow History',
    options: [{optionName: 'Download', optionType: 'workflowreport', optionUrl: '/reports', enabled: true}]
  },
  {
    icon: 'fa-file-excel-o',
    iconUrl: '/reports',
    name: 'Issuer Disclosures',
    description: 'Click on the link below to download the Issuer Disclosures',
    options: [{optionName: 'Download', optionType: 'issuerreportdownload', optionUrl: '/reports', enabled: true}]
  }
];
