import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertService, ApiService, AuthenticationService} from '../_services';
import _ from 'lodash';
import {saveAs} from 'file-saver/dist/FileSaver';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  templateUrl: './morningcall.component.html',
  styleUrls: ['./all.documents.component.scss']
})

export class MorningcallComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  columnDefs = [];
  documentSynopsis = [];
  productTypedistribution: any;
  compendium: any;
  rowHeight = 30;
  paginationPageSize = 10;
  rowSelection = 'multiple';
  selectedDoc: any;
  selectedProducts = [];
  distributionGroups = ['Limited', 'Unlimited'];
  createNewCompendiumDocEnabled = false;
  checkInDocument: any;
  checkInDocumentName: string;
  checkInDocumentSelected = false;

  columnDefsCalendar = [];
  rowDataCalendar = [];

  user:any;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;


  constructor(private apiService: ApiService, private authService: AuthenticationService,
              private router: Router, private alertService: AlertService) {
    
    this.authService.user.subscribe(value => (this.user = value));

    this.columnDefs = [
      {
        headerName: 'DocumentID',
        field: 'docID',
        width: 130,
        cellClass: 'cell-wrap-text',
        autoHeight: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {
        headerName: 'Primary Issuer',
        field: 'primaryIssuer',
        width: 200,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Document Type',
        field: 'docTypeName',
        width: 130,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Published',
        field: 'publishDateTime',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Primary Analyst',
        field: 'primaryAnalyst',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Headline',
        field: 'headLine',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      }];
    this.checkInDocumentName = 'Choose file';

    this.columnDefsCalendar = [
      {
        headerName: 'Published Date',
        field: 'publishedDate',
        width: 355,
        cellClass: 'cell-wrap-text',
        autoHeight: false,
      },
      {
        headerName: 'Published By',
        field: 'publishedBy',
        width: 340,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      }
    ]
  }

  onGridReady(params) {
    let selectedNodes = [];
    if (this.gridApi && this.gridApi.getSelectedNodes().length) {
      selectedNodes = this.gridApi.getSelectedNodes().map(node => node.data.docID);
    }
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    // This is to persist the state of checkbox when navigating between tabs
    this.gridApi.forEachNode(node => node.setSelected(selectedNodes.includes(node.data.docID)));
  }

  ngOnInit() {
    this.apiService.getMorningCallDocuments().subscribe(data => {
      this.compendium = _.cloneDeep(data.compendium);
      this.documentSynopsis = this.compendium.documentSynopsis;
      this.productTypedistribution = this.compendium.productTypedistributions[0];
    });

    this.apiService.fetchAllEventData().subscribe(data=>{
      this.rowDataCalendar = data;

    })

  }

  onFileChange(event, fileInput): void {

    if (`MC_${moment().format('MMDDYYYY')}.doc` === event.target.files[0].name) {
      this.checkInDocument = event.target.files[0];
      this.checkInDocumentName = event.target.files[0].name;
      this.checkInDocumentSelected = true;
        if (!this.createNewCompendiumDocEnabled) {
          this.alertService.error('Please select document(s) from compendium.');
        }
    } else {
      this.alertService.error('Please upload a valid document');
    }
    fileInput.value = null;

  }

  onCheckIn(): void {
    const formData = new FormData();
    formData.append('uploadDocument', this.checkInDocument);
    formData.append('actionDestination', 'workflow');
    formData.append('documentID', this.checkInDocumentName.replace(/\.[^/.]+$/, ''));
    formData.append('actionFileName', this.checkInDocumentName);
    formData.append('products', JSON.stringify(this.selectedProducts));
    formData.append('distributions', JSON.stringify(this.productTypedistribution.distributionRecipient));
    formData.append('loginName', this.authService.user.value.loginName);
    this.apiService.checkinCompendiumDocument(formData).subscribe(response => {
      this.checkInDocument = null;
      this.checkInDocumentName = 'Choose file';
      this.checkInDocumentSelected = false;
      this.router.navigate(['/documents/recentlypublished']);
    });
  }

  onSelectionChanged() {
    this.createNewCompendiumDocEnabled = this.gridApi.getSelectedNodes().length > 0;
    this.selectedProducts = this.gridApi.getSelectedNodes().map(row => {
      return {productID: row.data.productID, versonID: row.data.versionID};
    });
  }

  onRowClicked({data}) {
    this.selectedDoc = _.cloneDeep(data);
  }

  onCreateNewCompendiumDocument() {
    const requestBody = _.cloneDeep(this.compendium);
    requestBody.documentSynopsis = this.gridApi.getSelectedRows().map(row => ({...row, ...{selected: true}}))
    requestBody.distributionRecipient = requestBody.productTypedistributions[0].distributionRecipient;
    delete requestBody['productTypedistributions'];
    this.apiService.createNewCompendiumDocument(requestBody).subscribe(document => {

      this.apiService.downloadNewCompendiumDocument(document.replace(/\.[^/.]+$/, '')).subscribe(response => {
        const blob = new Blob([response], {type: 'application/msword'});
        saveAs(blob, document);
      });

    });
  }

  onDistributionGroupChange() {
    this.productTypedistribution.distributionRecipient = this.productTypedistribution.distributionRecipient.map(recipient => {
      if (recipient.name.indexOf(this.distributionGroups[0]) !== -1 ||
        recipient.name.indexOf(this.distributionGroups[1]) !== -1) {
        recipient.editable = recipient.name.indexOf(this.productTypedistribution.distributionGroup) !== -1;
        recipient.selected = recipient.name.indexOf(this.productTypedistribution.distributionGroup) !== -1;
      }
      return recipient;
    });
  }

  selectedFile: File = null;

  
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.selectedFile = input.files[0];
    }
  }

  onCancelled() {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = '';
  }
  onUpload() {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('excelFile', this.selectedFile, this.selectedFile.name);
      formData.append('username', this.user.loginName);


      this.apiService.uploadEventExcel(formData).subscribe((res:any) => {
      if(res.statusCode == 200){
          this.selectedFile = null;
          this.fileInput.nativeElement.value = '';

          this.apiService.fetchAllEventData().subscribe(data=>{
            this.rowDataCalendar = data;
      
          })
          // this.alertService.success('event calendar file uploaded successfully');
      }
      }, error =>{
          // this.alertService.error('some error while uploading event calendar');
      }
  );
    } 
     }
}
