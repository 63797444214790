import {ICellRendererAngularComp} from 'ag-grid-angular/src/interfaces';
import {Component} from '@angular/core';
import {ApiService, AuthenticationService, DataService} from '../_services';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {saveAs} from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-evetCalendar-action-cell',
  template: `
    <div>
      
      <a (click)="clickedAction('delete', content)">
        <i class="fa fa-trash-o mr-4" title="Delete"></i>
      </a>

      <a (click)="downloadEvent()">
        <i class="fa fa-download ml-2 mr-4" title="Download File"></i>
      </a>
    </div>
    <ng-template #content let-c="close()" let-d="dismiss()">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{headerText}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span *ngIf="params.colDef.headerName === 'Action'">{{messageBody}}</span>
      </div>
      <div class="modal-footer">
        <div class="pull-left" [ngStyle]="{'visibility': params.colDef.headerName === 'Action' ? 'visible' : 'hidden'}">
          <button type="button" class="btn btn-outline-dark pull-left" (click)="continueAction(selectedAction); c">{{actionText}}</button>
        </div>
        <div class="pull-right">
          <button type="button" class="btn btn-outline-dark" (click)="c">{{closeText}}</button>
        </div>
      </div>
    </ng-template>
    `,
  styles: [`
    .modal-footer {
      justify-content: space-between;
    }

    .fa {
      font-size: 1.5em;
    }
  `]
})
export class EventCalendarActionRendererComponent implements ICellRendererAngularComp {

  params: any;
  user: any;
  closeResult: any;
  messageBody: string;
  closeText: string;
  headerText: string;
  actionText: string;
  selectedAction: string;

  constructor(private modalService: NgbModal,
              private authService: AuthenticationService,
              private apiService: ApiService
            ) {
  }

  agInit(params: any): void {
    this.params = params;
    this.user = this.authService.user.getValue();
  }


  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  clickedAction(action, content): void {
    this.selectedAction = action;
     if (action === 'delete') {
      this.headerText = 'Delete';
        this.messageBody = 'Are you sure you want to delete this document ?';
        this.actionText = 'Ok';
        this.closeText = 'Cancel';
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } 
  }

  continueAction(action) {
    this.params.api.componentParent.onActionCellClicked(action, this.params.data);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  downloadEvent(){
    this.apiService.downloadEventFile(this.params.data.eventId).subscribe(
      (response:any) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =  `Event.xlsx`; 
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error:any) => {
        console.error('Error downloading the file', error);
      });
    }
    

}
