import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  user: any;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {
    this.authService.user.subscribe(value => (this.user = value));
  }

  getDocuments(stage): Observable<any> {
    return this.http.post('/persons/documents', {stageName: stage});
  }

  getProfile(): Observable<any> {
    return this.http.post(`/persons/person/${this.user.loginName}`, {});
  }

  getAdminData(data): Observable<any> {
    return this.http.post('/admin/data', {dataType: data});
  }

  getIssuerData(): Observable<any>{
    return this.http.post('/issuers/data',{});
  }

  downloadChart(ticker): Observable<any>{
    return this.http.get(`/documents/downloadPriceChartForDisclosures?priceChartFile=${ticker}`);
  }
  
  getMacroData(): Observable<any>{
    return this.http.post(`/admin/ratingdistributions/disclosurepage`,{})
  }

  getIssuerDisclosuresDetails(issuerID): Observable<any>{
    return this.http.post(`/issuers/previousIssuer?issuerID=${issuerID}`,{});
  }
  getPrimaryIssuers(): Observable<any> {
    return this.http.post('/persons/primaryissuers', {});
  }
  
  getPublishedMatrix(data): Observable<any>{
    return this.http.post('/actionnotes/getMatrix', data)
  }

  getSecondaryIssuers(): Observable<any> {
    return this.http.post('/persons/secondaryissuers', {});
  }

  getPrimaryIndustries(): Observable<any> {
    return this.http.post('/persons/primaryindustries', {});
  }

  getAnalysts(): Observable<any> {
    return this.http.post('/persons/analyst', {});
  }

  createDocument(requestBody): Observable<any> {
    return this.http.post('/actionnotes/create', requestBody);
  }

  createSpecifiedDocument(documentType, requestBody): Observable<any> {
    return this.http.post(`/actionnotes/create/${documentType}`, requestBody);
  }

  updateDocument(documentType, requestBody): Observable<any> {
    return this.http.post(`/actionnotes/update/${documentType}/metadata`, requestBody);
  }

  openDocument(requestBody): Observable<any> {
    return this.http.post('/actionnotes/open', requestBody);
  }

  closeDocument(requestBody): Observable<any> {
    return this.http.post('/actionnotes/close', requestBody);
  }

  updateCompanyStatistics(requestBody, action): Observable<any> {
    return this.http.post(`/actionnotes/${action}/companyreport/companystatistics`, requestBody);
  }

  updateCompanyEarning(requestBody, action): Observable<any> {
    return this.http.post(`/actionnotes/${action}/companyreport/companyearning`, requestBody);
  }

  saveDocumentComments(productType, requestBody): Observable<any> {
    return this.http.post(`/actionnotes/update/${productType}/comments`, requestBody);
  }

  uploadFinancialModel(requestBody): Observable<any> {
    return this.http.post('/documents/upload/excelsheet', requestBody);
  }

  importFinancialMatrix(requestBody, options?:any): Observable<any> {
    return this.http.post('/actionnotes/import/financialmatrix', requestBody, options);
  }

  // getBlankTemplateFileName(productType, documentId): Observable<any> {
  //   return this.http.get(`/documents/get/blanktemplate/filename/${this.user.loginName}/${productType}/${documentId}`);
  // }

  downloadBlankTemplate(productType, documentId): Observable<any> {
    return this.http.get(`/documents/download/blanktemplate/${this.user.loginName}/${productType}/${documentId}`,
    {responseType: 'arraybuffer', observe: 'response'});
  }

  downloadTextTemplate(productId, documentId): Observable<any> {
    return this.http.get(`/documents/download/textfile/${productId}/${documentId}`, {responseType: 'arraybuffer'});
  }

  downloadBlankWorkbook(): Observable<any> {
    return this.http.get(`/documents/download/blankexcel/${this.user.loginName}`,
      { responseType: 'arraybuffer', observe: 'response' });
  }

  checkoutDocument(productType, actionDestination, documentId, stage): Observable<any> {
    return this.http.get(`/documents/download/checkoutdocument/` +
      `${this.user.loginName}/${productType}/${documentId}/${actionDestination}/${stage}`, 
      {responseType: 'arraybuffer', observe: 'response'});
  }

  // downlaodWordDocument(requestBody): Observable<any> {
  //   return this.http.post(`/documents/download/checkoutdocument`, requestBody, {responseType: "arraybuffer"});
  // }

  checkinDocument(requestBody): Observable<any> {
    return this.http.post('/documents/upload/checkindocument', requestBody);
  }

  checkinCompendiumDocument(requestBody): Observable<any> {
    return this.http.post('/documents/upload/morningcall', requestBody);
  }

  actionDocument(requestBody, action): Observable<any> {
    return this.http.post(`/documentstages/${action}`, requestBody);
  }

  downloadPdf(documentId): Observable<any> {
    return this.http.get(`/documents/downloadpdf/${this.user.loginName}/${documentId}`, {responseType: 'arraybuffer'});
  }

  saveDistribution(requestBody): Observable<any> {
    return this.http.post('/actionnotes/update/companyreport/distribution', requestBody);
  }

  getDistributionRecipients(requestBody): Observable<any> {
    return this.http.post('/distribution/productdistributionrecipients', requestBody);
  }

  getMorningCallDocuments(): Observable<any> {
    return this.http.post('/actionnotes/morningcall/doclist', {});
  }

  createNewCompendiumDocument(requestBody): Observable<any> {
    return this.http.post('/actionnotes/download/morningcall', requestBody);
  }

  downloadNewCompendiumDocument(documentId): Observable<any> {
    return this.http.get(`/documents/download/mccheckoutdocument/${documentId}`, {responseType: 'arraybuffer'});
  }

  downloadReport(type): Observable<any> {
    return this.http.get(`/documents/download/${type}/${this.user.loginName}`, { responseType: 'arraybuffer', observe: 'response' });
  }

  ratingsDistribution(action, requestBody): Observable<any> {
    return this.http.post(`/admin/ratingdistributions/${action}`, requestBody);
  }

  downloadAddInInstaller(type): Observable<any> {
    return this.http.get(`/documents/download/installer?installerType=${type}`, { responseType: 'arraybuffer', observe: 'response' });
  }

  importCurrentPrice(requestBody): Observable<any> {
    return this.http.post('/actionnotes/import/currentprice', requestBody);
  }
  fetchIssuerDetails(requestBody): Observable<any> {
    return this.http.post(`/products/issuers`,requestBody)
  }
  updateIssuerDetails(productID, data): Observable<any> {
      return this.http.post(`/products/issuers/save?productid=${productID}`, data)
  }

  getAbstractHtml(productId: number): Observable<any>{
    return this.http.get('/products/abstractHtml', {params: {productId: productId.toString() }})
  }

  uploadEventExcel(requestBody){
    return this.http.post('/events/upload', requestBody);
  }

  fetchAllEventData(): Observable<any>{
    return this.http.get(`/events/getAllEvent/${this.user.loginName}`);
  }
  
  previewHTmlBody(requestBody): Observable<any> {
    return this.http.post('/actionnotes/previewhtml', requestBody);
  }
  
  deleteEventFile(eventId): Observable<any> {
    return this.http.post(`/events/deleteEvent/${eventId}/${this.user.loginName}`, {});
  }

  downloadEventHelpDoc(): Observable<any> {
    return this.http.get(`/events/download/documentation`, { responseType: 'blob'});
  }

  downloadEventFile(eventId) {
    return this.http.get(`/events/downloadEventFile/${eventId}/${this.user.loginName}`, {responseType: 'blob'})
    .pipe(
      catchError(this.handleError)  
    );
  }

  downloadEventBlankTemplate(): Observable<any> {
    return this.http.get(`/events/download/blanktemplate/${this.user.loginName}`, {responseType: 'blob'})
    .pipe(
      catchError(this.handleError)  
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';

    // If it's a server-side error
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // A backend error occurred (e.g., 404, 500, etc.)
      if (error.error && error.error.message) {
        errorMessage = error.error.message;  
      } else {
        errorMessage = `Backend error: ${error.status} - ${error.statusText}`;
      }
    }

    return throwError(errorMessage);
  }

}
