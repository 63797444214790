import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService, AuthenticationService} from '../_services';
import {environment} from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  counter = new BehaviorSubject<number>(0);

  constructor(private spinner: NgxSpinnerService, private alertService: AlertService, private authService: AuthenticationService) {
    this.counter.subscribe(value => {
      if (value > 0) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.counter.next(this.counter.getValue() + 1);
    // append BASE_URL
    request = request.clone({
      url: `${environment.API_URL}${request.url}`
    });
    if (request.url.includes('downloadPriceChartForDisclosures?priceChartFile')) {
        return next.handle(request).pipe(tap((response: HttpResponse<any>) => {
          if (response['body'] && response['body'].successMessage) {
            this.alertService.success(response['body'].successMessage);
          }
          return response;
        }),
        catchError((err: HttpResponse<any>) => {
          let errorResponse;
          if (err['error'] instanceof ArrayBuffer) {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(err['error']));
            errorResponse = JSON.parse(decodedString);
          } else {
            errorResponse = err['error'];
          }
          const error = errorResponse.userErrorMessage || err.statusText;
        /*Logout when SESS_E_01*/
         
          return throwError(errorResponse);
        }), finalize(() => {
          this.counter.next(this.counter.getValue() - 1);
        }));
    }
    // update request body with user name and password with jwt token if available
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser && request.method === 'POST') {
      request = request.clone({
        body: Object.assign(request.body, {
          loginName: currentUser.loginName,
          password: ''
        })
      });
    }

    return next.handle(request).pipe(tap((response: HttpResponse<any>) => {
        if (response['body'] && response['body'].successMessage) {
          this.alertService.success(response['body'].successMessage);
        }
        return response;
      }),
      catchError((err: HttpResponse<any>) => {
        let errorResponse;
        if (err['error'] instanceof ArrayBuffer) {
          const decodedString = String.fromCharCode.apply(null, new Uint8Array(err['error']));
          errorResponse = JSON.parse(decodedString);
        } else {
          errorResponse = err['error'];
        }
        const error = errorResponse.userErrorMessage || err.statusText;
      /*Logout when SESS_E_01*/
        if (errorResponse.exceptionCode === 'SESS_E_01') {
          this.authService.clearUser();
        }
        /*Don't show error modal when password change*/
        if (!['4', '7'].includes(errorResponse.exceptionCode)) {
          this.alertService.error(error);
        }
        return throwError(errorResponse);
      }), finalize(() => {
        this.counter.next(this.counter.getValue() - 1);
      }));
  }
}
