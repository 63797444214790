export class TickerDef {
  active = false;
  countryCode = 'US';
  exchange = 'AMEX';
  primary = false;
  symbolSet = 'S&P';
  tickerName = '';
}

export class IssuerDef {
  issuerName = '';
  sequence = '';
  active = true;
  fiscalUsesCalendar = false;
  managementTeam = '';
  legalName = '';
  parentName = '';
  primarySector = '';
  countryCode = '';
  fiscalYearEnd = 'December';
  logoURL = '';
  relatedDisclosures = '';
  directorshipStatement = '';
  priceChartURL = '';
  securityName = '';
  primaryAnalyst = '';
  secondaryAnalyst = '';
  firstCallID = '';
  reutersID = '';
  firstCallDirectID = '';
  priceChartIdentifier = '';
  iSIN = '';
  mailingListPrefix = '';
  tickerDef = [new TickerDef(), new TickerDef(), new TickerDef()];
  coverageTeam = [];
  cusip = '';
}
