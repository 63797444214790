import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {SectorDef} from './SectorDef';
import _ from 'lodash';
import {ApiService} from '../../_services';

@Component({
  templateUrl: 'industry.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class IndustryAdminComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  ISSUER_TAB = {GENERAL: 'GENERAL', SECURITY: 'SECURITY', DISCLOSURES: 'DISCLOSURES'};
  sectorDef: any;
  createView = false;
  userDef = [];
  countryCodeChoice = [];
  associateIndustriesList = [];
  exchangeChoice = [];
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedRow: any;
  monthsList = moment.months();
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'Industry Name',
      field: 'sectorName',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Status',
      field: 'active',
      width: 560,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      cellRenderer: this.IndustryStatusRenderer,
      sortable: true
    }
  ];

  IndustryStatusRenderer(params: any) {
    return params.value ? 'Active' : 'Inactive';
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  setTableHeight() {
    this.gridApi.sizeColumnsToFit();

    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.sectorDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length > this.paginationPageSize ? this.paginationPageSize : hotData.length;
    }
    const newHeight = ((hotLength) * 28) + 100;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  getRowNodeId({sectorID}) {
    return sectorID;
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
  }

  onSaveIndustry() {
    if (Array.isArray(this.selectedRow.analysts)) {
      this.selectedRow.analysts.forEach((analyst, index) => {
        analyst.sequence = index + 1;
      });
    }
    if (Array.isArray(this.selectedRow.associateIndustries)) {
      this.selectedRow.associateIndustries.forEach((industry, index) => {
        industry.sequence = index + 1;
      });
    }
    if (Array.isArray(this.selectedRow.sectorHeads)) {
      this.selectedRow.sectorHeads.forEach((sectorHead, index) => {
        sectorHead.sequence = index + 1;
      });
    }
    this.adminService.saveIndustry(this.selectedRow).subscribe((data: any) => {
      this.selectedRow.sectorID = this.selectedRow.sectorID || data.sectorID;
      if (!this.createView) {
        const rowNode = this.gridApi.getRowNode(this.selectedRow.sectorID);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  onPublishIndustry() {
    this.adminService.publishIndustry(this.selectedRow.sectorID).subscribe(data => {});
  }

  constructor(
    private adminService: AdminService,
    private router: Router,
    private apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.createView = this.router.url === '/industries/newIndustry';
    if (this.createView) {
      this.selectedRow = new SectorDef();
    }
    this.apiService.getAdminData('industry').subscribe((data: any) => {
      if (data.hasOwnProperty('admin')) {
        this.sectorDef = data.admin.sectorDef.map(sector => sector);
        this.associateIndustriesList = data.admin.sectorDef.map(sector => {
          return {
            industryID: sector.sectorID,
            industryName: sector.sectorName
          };
        });
        this.userDef = data.admin.userDef.map(user => {
          return {displayName: user.displayName, userID: user.userID};
        });
        this.countryCodeChoice = data.admin.countryCodeChoice.map(country => country);
        this.exchangeChoice = data.admin.exchangeChoice.map(exchange => exchange);
        this.setTableHeight();
      }
    });
  }
}
