import {Injectable} from '@angular/core';
import {ApiService, AuthenticationService} from '../_services';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AdminService {
  user: any;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthenticationService
  ) {
    this.authService.user.subscribe(value => (this.user = value));
  }

  getUserPermissions(userID) {
    return this.http.post('/admin/users/permissions', {userID});
  }

  saveUserGeneralDetails(userDef) {
    return this.http.post('/admin/users/saveusergeneral', {userDef});
  }

  publishUserGeneralDetails(publishUserId) {
    return this.http.post('/admin/users/publishuser', {publishUserId});
  }

  updateUserPermission(rootPermission, userID, userLoginName) {
    return this.http.post('/admin/users/savepermissions', {rootPermission, userID, userLoginName});
  }

  saveIssuerGeneral(issuerDef) {
    return this.http.post('/issuers/saveissuer', {issuerDef});
  }

  saveIssuerSecurity(issuerDef) {
    return this.http.post('/issuers/saveSecurity', {issuerDef});
  }

  saveIssuerDisclosures(issuerDef) {
    return this.http.post('/issuers/saveDisclosures', {issuerDef});
  }

  publishIssuer(issuerID) {
    return this.http.post('/issuers/publish', {issuerID});
  }

  saveIndustry(sectorDef) {
    return this.http.post('/industry/updateindustry', {sectorDef});
  }

  publishIndustry(sectorID) {
    return this.http.post('/industry/publish', {sectorID});
  }

  saveDistributorRecipients(payload) {
    return this.http.post('/distribution/updateRecipients', payload);
  }

}
