import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataService {
  private breadcrumbSource = new BehaviorSubject('Default Message');
  breadcrumb = this.breadcrumbSource.asObservable();
  private _openDocumentData: any;
  private _selectedStage: string;

  get selectedStage(): string {
    return this._selectedStage || 'Authoring';
  }

  set selectedStage(value: string) {
    this._selectedStage = value;
  }

  get openDocumentData(): any {
    return this._openDocumentData;
  }

  set openDocumentData(value: any) {
    this._openDocumentData = value;
  }

  constructor() {
  }

  changeBreadcrumb(message: any) {
    this.breadcrumbSource.next(message);
  }

  getFilename(response: any, filename: string) {
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }																																																																																																																																																																																																																																																																																																				 
}
