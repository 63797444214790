import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-content',
  templateUrl: 'alert.component.html',
})
export class NgbdModalComponent {
  @Input() message;
  @Input() header;

  constructor(public activeModal: NgbActiveModal) {
  }
}
