import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationService {
  user: any;

  constructor(private http: HttpClient, private router: Router) {
    this.user = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));
  }

  login(username, password, content) {
    const requestBody = {
      loginName: username,
      password: password
    };
    return this.http
      .post<any>('/auth/login', requestBody);
  }

  changePassword(requestBody): Observable<any> {
    return this.http.post('/admin/users/changePassword', requestBody);
  }

  logout() {
    this.http.post<any>('/auth/logout', {}).subscribe();
    this.clearUser();
  }

  clearUser() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.user.next(null);
    this.router.navigate(['/login']);
  }
}
