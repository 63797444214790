import {Component, OnInit} from '@angular/core';
import {AdminPanel} from '../common/AdminPanel';
import {panels} from './panelData';
import {ApiService, DataService} from '../_services';
import {saveAs} from 'file-saver/dist/FileSaver';

@Component({templateUrl: 'reports.component.html'})
export class ReportsComponent implements OnInit {
  adminPanels = [];

  constructor(private apiService: ApiService, private dataService: DataService) {
  }

  addAdminPanel() {
    panels.forEach(panel => {
      this.adminPanels.push(
        new AdminPanel(
          panel.icon,
          panel.iconUrl,
          panel.name,
          panel.description,
          panel.options
        )
      );
    });
  }

  downloadReport(event) {
    this.apiService.downloadReport(event).subscribe(response => {
      const blob = new Blob([response.body], {type: 'application/msexcel'});
      saveAs(blob, this.dataService.getFilename(response, 'WorkflowHistoryReport.csv'));
    });
  }

  ngOnInit() {
    this.addAdminPanel();
  }
}
