import {ICellRendererAngularComp} from 'ag-grid-angular/src/interfaces';
import {Component} from '@angular/core';
import {ApiService, AuthenticationService, DataService} from '../_services';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {saveAs} from 'file-saver/dist/FileSaver';

@Component({
  selector: 'app-document-action-cell',
  template: `
    <div>
      <a *ngIf="allowAction('close')" (click)="clickedAction('close', content)">
        <i class="fa fa-lock" title="Document locked"></i>
      </a>
      <a *ngIf="allowAction('approve')" (click)="clickedAction('approve', content)">
        <i class="fa fa-thumbs-o-up mr-4" title="Approve"></i>
      </a>
      <a *ngIf="allowAction('reject')" (click)="clickedAction('reject', content)">
        <i class="fa fa-thumbs-o-down mr-4" title="Reject"></i>
      </a>
      <a *ngIf="allowAction('delete')" (click)="clickedAction('delete', content)">
        <i class="fa fa-trash-o mr-4" title="Delete"></i>
      </a>
      <a *ngIf="allowAction('deletepublished')" (click)="clickedAction('deletepublished', recipientContent)">
        <i class="fa fa-trash-o mr-4" title="Delete Published"></i>
      </a>
      <a *ngIf="allowAction('recall')" (click)="clickedAction('recall', content)">
        <i class="fa fa-repeat mr-4" title="Recall"></i>
      </a>
      <a *ngIf="allowAction('resend')" (click)="clickedAction('resend', recipientContent)">
        <i class="fa fa-refresh" title="Resend"></i>
      </a>
      <a *ngIf="params.colDef.headerName === 'View'" (click)="downloadPdf(content)">
        <i class="fa fa-file-pdf-o mr-4" title="Show PDF"></i>
      </a>
      <a *ngIf="params.colDef.headerName === 'View'" (click)="downloadWord()">
        <i class="fa fa-file-word-o" title="Download Document"></i>
      </a>
    </div>
    <ng-template #content let-c="close()" let-d="dismiss()">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{headerText}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <object [attr.data]="pdfURL | safe" type="application/pdf" width="100%" height="500"
                *ngIf="params.colDef.headerName === 'View'"></object>
        <span *ngIf="params.colDef.headerName === 'Action'">{{messageBody}}</span>
      </div>
      <div class="modal-footer">
        <div class="pull-left" [ngStyle]="{'visibility': params.colDef.headerName === 'Action' ? 'visible' : 'hidden'}">
          <button type="button" class="btn btn-outline-dark pull-left" (click)="continueAction(selectedAction); c">{{actionText}}</button>
        </div>
        <div class="pull-right">
          <button type="button" class="btn btn-outline-dark" (click)="c">{{closeText}}</button>
          <button type="button" [ngStyle]="{'visibility': params.colDef.headerName === 'View' ? 'visible' : 'hidden'}"
                  class="btn btn-primary pull-left mr-2" (click)="savePdf(); c">Download
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template #recipientContent let-c="close()" let-d="dismiss()">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{recipientHeaderText}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body row">
        <div class="col-6">
          <div class="form-check mb-2" *ngFor="let distributer of distributions"
               [hidden]="!(distributer.name.includes('Limited') || distributer.name.includes('Unlimited'))">
            <input class="form-check-input" type="checkbox" id="{{distributer.name}}" [(ngModel)]="distributer.selected"
                   [disabled]="!distributer.editable">
            <label class="form-check-label" [for]="distributer.name">{{distributer.name}}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check mb-2" *ngFor="let distributer of distributions"
               [hidden]="(distributer.name.includes('Limited') || distributer.name.includes('Unlimited'))">
            <input class="form-check-input" type="checkbox" id="{{distributer.name}}" [(ngModel)]="distributer.selected"
                   [disabled]="!distributer.editable">
            <label class="form-check-label" [for]="distributer.name">{{distributer.name}}</label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="pull-left">
          <button type="button" class="btn btn-outline-dark pull-left" (click)="c">Close</button>
        </div>
        <div class="pull-right">
          <button type="button" class="btn btn-primary" (click)="continueAction(selectedAction); c"
                  [disabled]="!checkResendDisabled()">Continue
          </button>
        </div>
      </div>
    </ng-template>`,
  styles: [`
    .modal-footer {
      justify-content: space-between;
    }

    .fa {
      font-size: 1.5em;
    }
  `]
})
export class ActionRendererComponent implements ICellRendererAngularComp {

  params: any;
  user: any;
  pdfURL: any;
  pdf: any;
  closeResult: any;
  messageBody: string;
  closeText: string;
  headerText: string;
  actionText: string;
  recipientHeaderText: string;
  distributions: any;
  selectedAction: string;

  constructor(private apiService: ApiService, private modalService: NgbModal,
              private dataService: DataService, private authService: AuthenticationService) {
  }

  agInit(params: any): void {
    this.params = params;
    this.user = this.authService.user.getValue();
  }

  allowAction(action): boolean {
    action = this.params.data.disable || action; // Disable all actions if disable flag is true
    const isActionAllowedInCurrentStage = this.isAllowedForCurrentStage(action);
    switch (action) {
      case 'approve':
        return this.params.colDef.headerName === 'Action' && this.params.data.approve === 'Approve' && (!this.params.data.locked ||
          ('Authoring' === this.dataService.selectedStage && this.params.data.lockOwnerLoginName === this.user.loginName)) &&
          isActionAllowedInCurrentStage;
      case 'delete':
        return this.params.colDef.headerName === 'Action' &&
          (!this.params.data.locked || (this.user.pAPermission && this.user.sAPermission) ||
            ('Authoring' === this.dataService.selectedStage && this.params.data.lockOwnerLoginName === this.user.loginName)) &&
          isActionAllowedInCurrentStage;
      case 'recall':
        return this.params.colDef.headerName === 'Action' && this.params.data.recall === 'Recall' && isActionAllowedInCurrentStage;
      case 'resend':
        return this.params.colDef.headerName === 'Action' && this.user.pAPermission && this.user.sAPermission && !this.params.data.locked &&
          isActionAllowedInCurrentStage;
      case 'deletepublished':
        return this.params.colDef.headerName === 'Action' && !this.params.data.locked && this.user.pAPermission && this.user.sAPermission &&
          isActionAllowedInCurrentStage;
      case 'reject' :
        return this.params.colDef.headerName === 'Action' && this.params.data.approve === 'Approve' && isActionAllowedInCurrentStage && !this.params.data.locked;
      case 'close':
        return this.params.colDef.headerName === 'State' && this.params.data.locked;
      default:
        return false;
    }
  }

  isAllowedForCurrentStage(action): boolean {
    switch (action) {
      case 'approve':
        return ['Authoring', 'Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      case 'delete':
        return ['Authoring'].includes(this.dataService.selectedStage);
      case 'recall':
        return ['Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      case 'resend':
      case 'deletepublished':
        return ['Recently Published'].includes(this.dataService.selectedStage);
      case 'reject' :
        return ['Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage);
      default:
        return false;
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  clickedAction(action, content): void {
    this.selectedAction = action;
    if (action === 'approve') {
      if (this.dataService.selectedStage === 'Authoring') {
        this.headerText = 'Approve';
        this.messageBody = 'The analyst(s) primarily responsible for the preparation of this research report attests to the following: ' +
          '(1) that the views and opinions rendered in this research report reflect his or her personal views about the subject companies' +
          ' or issuers; and (2) that no part of the research analyst\'s compensation was, is, or will be directly related to the ' +
          'specific recommendations or views in this research report.';
        this.actionText = 'I certify';
        this.closeText = 'I do not certify';
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else if (this.dataService.selectedStage === 'Compliance') {
        this.headerText = 'Approve';
        this.messageBody = 'The Supervisory Analyst attests to the review and approval of the substance of this research report';
        this.actionText = 'Ok';
        this.closeText = 'Cancel';
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        this.continueAction(action);
      }
    } else if (action === 'deletepublished') {
      this.recipientHeaderText = 'Delete Document';
      const requestBody = {
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: this.params.data.productID,
        callType: false
      };
      this.apiService.getDistributionRecipients(requestBody).subscribe(response => {
        this.distributions = response.distributionRecipient;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
    } else if (action === 'resend') {
      this.recipientHeaderText = 'Resend Document';
      const requestBody = {
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: this.params.data.productID,
        callType: true
      };
      this.apiService.getDistributionRecipients(requestBody).subscribe(response => {
        this.distributions = response.distributionRecipient;
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      });
    } else if (action === 'close') {
      if (this.user.pAPermission && this.user.sAPermission &&
        ['Editing', 'Compliance', 'Production'].includes(this.dataService.selectedStage)) {
        this.continueAction(action);
      }
    } else if (action === 'delete') {
      this.headerText = 'Delete';
        this.messageBody = 'Are you sure you want to delete this document ?';
        this.actionText = 'Ok';
        this.closeText = 'Cancel';
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    } else {
      this.continueAction(action);
    }

  }

  continueAction(action) {
    this.params.api.componentParent.onActionCellClicked(action, this.params.data, this.distributions);
  }

  savePdf(): void {
    saveAs(this.pdf, `${this.params.data.docID}.pdf`);
    this.pdf = void 0;
  }

  downloadPdf(content): void {
    this.pdf = void 0;
    this.apiService.downloadPdf(this.params.data.docID).subscribe(response => {
      this.pdf = new Blob([response], {type: 'application/pdf'});
      this.pdfURL = window.URL.createObjectURL(this.pdf);
      this.headerText = 'PDF';
      this.closeText = 'Close';
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  downloadWord(): void {
    this.apiService.checkoutDocument(this.params.data.productName, 'workflow',
      this.params.data.docID, this.dataService.selectedStage).subscribe(response => {
      const blob = new Blob([response], {type: 'application/msword'});
      saveAs(blob, `${this.params.data.docID}.doc`);
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkResendDisabled() {
    if (this.distributions) {
      return this.distributions.some(function (distributer) {
        return distributer.selected;
      });
    } else {
      return false;
    }
  }

}
