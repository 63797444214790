export class UserDef {
  active = true;
  analystBiography = '';
  associates = [];
  coveredCompanies = [];
  displayName = '';
  analystDisclosures = '';
  firstName = '';
  lastName = '';
  title = '';
  phone = '';
  fax = '';
  loginName = '';
  city = '';
  state = '';
  street = '';
  zip = '';
  password = '';
  email = '';
  mi = '';
}

