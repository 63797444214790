import {IHeaderAngularComp} from 'ag-grid-angular/src/interfaces';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-header',
  template: `
    <div>
      <div class="customHeaderLabel">
        <input *ngIf="params.showRadio" type="radio" [value]="params.displayName" [(ngModel)]="selectedRadio"
               name="radio{{params.__metadata__.year}}" (click)="changeRadioSelect()" ngDefaultControl/>
        {{params.displayName}}
      </div>
      <div *ngIf="params.showSelect" class="customSelectDiv">
        <select name="select{{params.__metadata__.year}}" [(ngModel)]="selectedValue" (change)="changeSelectValue()" ngDefaultControl>
          <option *ngFor="let o of params.selectValues" [value]="o">
            {{o}}
          </option>
        </select>
      </div>
    </div>`,
  styles: []
})
export class CustomHeaderRendererComponent implements IHeaderAngularComp {

  params: any;
  @Input()
  selectedRadio: any;
  @Input()
  selectedValue: any;

  agInit(params: any): void {
    this.params = params;
    switch (this.params.displayName) {
      case 'Q1':
        this.selectedValue = this.params.__metadata__.q1ActualEstimate;
        break;
      case 'Q2':
        this.selectedValue = this.params.__metadata__.q2ActualEstimate;
        break;
      case 'Q3':
        this.selectedValue = this.params.__metadata__.q3ActualEstimate;
        break;
      case 'Q4':
        this.selectedValue = this.params.__metadata__.q4ActualEstimate;
        break;
      case 'FY':
        this.selectedValue = this.params.__metadata__.fYActualEstimate;
        break;
      case 'CY':
        this.selectedValue = this.params.__metadata__.cYActualEstimate;
        break;
    }
    if (this.params.showSelect) {
      if (this.params.__metadata__.fYSelected) {
        this.selectedRadio = 'FY';
      } else if (this.params.__metadata__.cYSelected) {
        this.selectedRadio = 'CY';
      } else {
        this.selectedRadio = false;
      }
    }
  }

  changeSelectValue(): void {
    switch (this.params.displayName) {
      case 'Q1':
        this.params.__metadata__.q1ActualEstimate = this.selectedValue;
        break;
      case 'Q2':
        this.params.__metadata__.q2ActualEstimate = this.selectedValue;
        break;
      case 'Q3':
        this.params.__metadata__.q3ActualEstimate = this.selectedValue;
        break;
      case 'Q4':
        this.params.__metadata__.q4ActualEstimate = this.selectedValue;
        break;
      case 'FY':
        this.params.__metadata__.fYActualEstimate = this.selectedValue;
        break;
      case 'CY':
        this.params.__metadata__.cYActualEstimate = this.selectedValue;
        break;
    }
    this.params.api.reset.next(true);

  }

  changeRadioSelect(): void {
    switch (this.params.displayName) {
      case 'FY':
        this.params.__metadata__.fYSelected = true;
        this.params.__metadata__.cYSelected = false;
        break;
      case 'CY':
        this.params.__metadata__.fYSelected = false;
        this.params.__metadata__.cYSelected = true;
        break;
      default:
        console.warn('Invalid Display name');
    }
    this.params.api.reset.next(true);
  }


}
