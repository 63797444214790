import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home';
import {LoginComponent} from './login';
import {ReportsComponent} from './reports';
import {
  AdminComponent,
  DistributorAdminComponent,
  IndustryAdminComponent,
  IssuerAdminComponent,
  UserAdminComponent
} from './administration';
import {AuthGuard} from './_guards';
import {AllDocumentsComponent, CompanyreportComponent, FlashnoteComponent, IndustryChangeReportComponent, IndustryreportComponent, MorningcallComponent} from './documents';
import {NotFoundComponent} from './404/404.component';
import {RatingsDistributionComponent} from './administration/distributor_administration/ratings.distribution.component';
import { DisclosureComponent } from './disclosure/disclosure.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'newdoc/morningcall',
    component: MorningcallComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users/allUser',
    component: UserAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'users/newUser',
    component: UserAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'issuers/allIssuer',
    component: IssuerAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'issuers/newIssuer',
    component: IssuerAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'distributors/allDistributor',
    component: DistributorAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'distributors/ratings',
    component: RatingsDistributionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'industries/allIndustries',
    component: IndustryAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'industries/newIndustry',
    component: IndustryAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    component: AllDocumentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'documents/:action',
    component: AllDocumentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'companyreport/:action',
    component: CompanyreportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'flashnote/:action',
    component: FlashnoteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'industryreport/:action',
    component: IndustryreportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'industryChangeReport/:action',
    component: IndustryChangeReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {path: 'login', component: LoginComponent},
  {
    path: 'disclosures',
    component: DisclosureComponent
  },

  // otherwise redirect to home
  {path: '**', component: NotFoundComponent, canActivate: [AuthGuard]}
];

export const routing = RouterModule.forRoot(appRoutes);
