import {Component, OnInit} from '@angular/core';
import {ApiService, AuthenticationService} from './_services';
import {AdminService} from './administration/admin.service';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordValidation} from './_helpers';
import { environment } from 'src/environments/environment.prod';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  userSubscription: Subscription;
  isLoggedIn: boolean;
  currentYear: Number;
  user: any;
  profileData: any;
  rootPermission: any;
  changePasswordForm: FormGroup;
  params: any;
  currentRoute: any;
  displayHeader = true;
  navItem = [
    {
      name: 'Home',
      disabled: false,
      href: '/home',
      iconClass: 'fa fa-home mr-2 mt-2'
    },
    {
      name: 'Document',
      disabled: false,
      href: '/documents',
      iconClass: 'fa fa-plus-square-o mr-2 mt-2'
    },
    {
      name: 'Administration',
      disabled: true,
      href: '/admin',
      iconClass: 'fa fa-user-plus mr-2 mt-2'
    },
    {
      name: 'Reports',
      disabled: true,
      href: '/reports',
      iconClass: 'fa fa-book mr-2 mt-2'
    }
  ];

  constructor(private authService: AuthenticationService,
              private modalService: NgbModal,
              private adminService: AdminService,
              private  apiService: ApiService,
              private router: Router) {

    this.changePasswordForm = new FormGroup({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, {
      validators: PasswordValidation
    });
   
    router.events.subscribe(event =>{
      if (event instanceof NavigationEnd) {
          this.displayHeader = event.url != '/disclosures';
          
      }
        
    })
    
    
  }

  showChangePassword(content): void {
    this.changePasswordForm.setValue({
      username: this.authService.user.getValue().loginName,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onToggleExpand(sector: any) {
    sector.expand = !sector.expand;
    sector.toggleIcon = sector.toggleIcon === 'fa-plus-circle' ? 'fa-minus-circle' : 'fa-plus-circle';
  }

  showProfile(profile): void {
    this.apiService.getProfile().subscribe(response => {
      this.profileData = response;
      this.profileData.active = !!this.profileData.active;
      this.adminService
        .getUserPermissions(this.profileData.userID)
        .subscribe((permissions: any) => {
          this.rootPermission = permissions.rootPermission;
          this.rootPermission.sectorPermission = this.rootPermission.sectorPermission.map(sector => {
              sector.expand = false;
              sector.toggleIcon = 'fa-plus-circle';
              return sector;
            }
          );
          this.modalService.open(profile, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg',
            windowClass: 'profile-modal',
            container: 'app-root'
          });
        });
    });
  }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe(user => {
      this.isLoggedIn = !!user;
      this.user = user;
      if (this.isLoggedIn) {
        this.navItem.forEach((value) => {
          if (!['Home', 'Document'].includes(value.name)) {
            value.disabled = !(user.pAPermission || user.sAPermission);
          }
        });
      }
    });
    this.currentYear = new Date().getFullYear();
  }

  changePassword(): void {
    const requestBody = {
      loginName: this.user.loginName,
      userName: this.changePasswordForm.get('username').value,
      currentPassword: this.changePasswordForm.get('oldPassword').value,
      newPassword: this.changePasswordForm.get('newPassword').value,
      newPasswordAgain: this.changePasswordForm.get('confirmPassword').value
    };
    this.authService.changePassword(requestBody).subscribe();
  }

  logoutUser(): void {
    this.authService.logout();
  }
}
