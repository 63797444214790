import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin.service';
import * as moment from 'moment';
import _ from 'lodash';
import {ApiService} from '../../_services';

@Component({
  templateUrl: 'distributor.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class DistributorAdminComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  ISSUER_TAB = {GENERAL: 'GENERAL', SECURITY: 'SECURITY', DISCLOSURES: 'DISCLOSURES'};
  distributionDef = [];
  distributionGroups = [];
  productTypedistributions: any;
  countryCodeChoice = [];
  exchangeChoice = [];
  paginationPageSize = 10;
  rowSelection = 'single';
  selectedRow: any;
  monthsList = moment.months();
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'Product Type',
      field: 'productType',
      width: 540,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Distribution Group',
      field: 'distributionGroup',
      width: 540,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }
  ];

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  setTableHeight() {
    this.gridApi.sizeColumnsToFit();

    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.productTypedistributions || [];
    if (hotData.length > 0) {
      hotLength = hotData.length > this.paginationPageSize ? this.paginationPageSize : hotData.length;
    }
    const newHeight = ((hotLength) * 28) + 100;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  getRowNodeId({productType}) {
    return productType;
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
  }

  onSaveDistributor() {
    this.adminService.saveDistributorRecipients(this.selectedRow).subscribe(() => {
      const rowNode = this.gridApi.getRowNode(this.selectedRow.productType);
      rowNode.setData(this.selectedRow);
    });
  }


  onDistributionGroupChange() {
    this.selectedRow.distributionRecipient = this.selectedRow.distributionRecipient.map(recipient => {
      if (recipient.name.indexOf(this.distributionGroups[0]) !== -1 ||
        recipient.name.indexOf(this.distributionGroups[1]) !== -1) {
        recipient.editable = recipient.name.indexOf(this.selectedRow.distributionGroup) !== -1;
        recipient.selected = recipient.name.indexOf(this.selectedRow.distributionGroup) !== -1;
      }
      return recipient;
    });
  }

  constructor(
    private adminService: AdminService,
    private apiService: ApiService
  ) {

  }

  ngOnInit() {
    this.apiService.getAdminData('distribution').subscribe((data: any) => {
      if (data.hasOwnProperty('admin')) {
        this.distributionDef = data.admin.distributionDef.map(distribution => distribution);
        this.countryCodeChoice = data.admin.countryCodeChoice.map(country => country);
        this.exchangeChoice = data.admin.exchangeChoice.map(exchange => exchange);
        this.productTypedistributions = data.admin.productTypedistributions.map(productTypedistribution => productTypedistribution);
        this.distributionGroups = data.admin.distributionGroups.map(distributionGroup => distributionGroup);
        this.setTableHeight();
      }
    });
  }
}
