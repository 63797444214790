import {ICellRendererAngularComp} from 'ag-grid-angular/src/interfaces';
import {Component} from '@angular/core';

@Component({
  selector: 'app-document-checkbox-cell',
  template: `<input type="checkbox" [(ngModel)]="this.params.value" (change)="setValue(this.params)">`
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  setValue(event): void {
    event.data[event.colDef.field] = event.value;
    this.params.setValue(event.value);
  }

}
