import {currencies} from './commonData';

export class DocumentModel {
  selectedPrimaryIssuer: string;
  selectedPrimaryIndustry: string;
  primaryIssuers: any;
  primaryAuthors: any;
  primaryIndustries: any;
  secondaryIssuers: any;
  filteredSecondaryIssuers: any;
  selectedSubjectCode: string;
  subjectCodes: any;
  targetPrice: string;
  abstract: string;
  investmentRisk: string;
  valuationMethodology: string;
  companyDescription: string;
  selectedRegion: string;
  regions: any;
  selectedSectorRecommendation: string;
  sectorRecommendations: any;
  selectedCurrentRecommendation: string;
  currentRecommendations: any;
  selectedRecommendationAction: string;
  selectedTargetPriceAction: string;
  actions: any;
  selectedCurrency: string;
  currencies: any;
  actionNote: any;
  // priceValidator: Function;
  iwcPriceValidator: any;
  industryRecommendations: any;
  priceValidator: Function;
  constructor() {
    this.actionNote = {};
    this.subjectCodes = [
      'Raising Recommendation',
      'Lowering Recommendation',
      'Raising Target Price',
      'Lowering Target Price',
      'Company Update',
      'Industry Overview',
      'Initiation of Coverage',
      'Change in Earnings Forecast',
      'Analyst Presentation',
      'Top Recommendations',
      'Change in Coverage',
      'Terminating Coverage'
    ];
    this.regions = ['region1', 'region2'];
    this.sectorRecommendations = ['Overweight', 'Market Weight', 'Underweight'];
    this.currentRecommendations = [
      'Buy',
      'Hold',
      'Sell',
      'Speculative Buy',
      'Speculative Hold',
      'Speculative Sell',
      'Suspending Coverage'
    ];
    this.actions = ['Increase', 'Decrease'];
    this.currencies = currencies;

    this.priceValidator = function (type) {
      let value = '';
      if (type === 'currentPrice') {
        value = this.actionNote.primaryIssuer.currentPrice;
      } else {
        value = this.actionNote.primaryIssuer.targetPrice;
      }
      if (value === "") {
        return true;
      } else {
        return (value === 'NA' || value === 'N/A' || !isNaN(Number(value)))
      }
    }
    this.iwcPriceValidator = function(type, val){
      let value = '';
      if (type === 'iwcPrice') {
        value = val.model;
      } else {
        value = val;
      }
      if (value === "") {
        return true;
      } else {
        return (value === 'NA' || value === 'N/A' || !isNaN(Number(value)))
      }
    }
  }
}
