import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-document-headline-cell',
  template: `{{getHeadlineText()}}
  <a *ngIf="showMore" placement="right" class="text-primary"
     ngbPopover="{{params.value}}" container="body" triggers="mouseenter:mouseleave">...more</a>`
})
export class DocumentHeadlineRendererComponent implements ICellRendererAngularComp {
  public params: any;
  private headlineText: string;
  showMore: boolean;

  setHeadlineText() {
    this.headlineText = this.params.value;
    this.showMore = false;
    if (this.headlineText.length > 30) {
      this.headlineText = this.headlineText.substr(0, 30);
      this.showMore = true;
    }
  }

  getHeadlineText() {
    return this.headlineText;
  }

  agInit(params: any): void {
    this.params = params;
    this.setHeadlineText();
  }

  refresh(params: any): boolean {
    this.params = params;
    this.setHeadlineText();
    return true;
  }
}
