import {ICellRendererAngularComp} from 'ag-grid-angular/src/interfaces';
import {Component} from '@angular/core';
import {DataService} from '../_services';
import {Router} from '@angular/router';

@Component({
  selector: 'app-document-open-cell',
  template: `<span [ngSwitch]="this.params.data.disable || this.params.data.view">
    <a class="text-primary" (click)="openDocument()" *ngSwitchCase="'View'">{{this.params.value}}</a>
    <span *ngSwitchDefault>{{this.params.value}}</span>
  </span>`
})
export class DocumentOpenRendererComponent implements ICellRendererAngularComp {
  public params: any;

  constructor(private dataService: DataService, private router: Router) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  openDocument(): void {
    this.dataService.openDocumentData = this.params.data;
    
    if (this.params.data.productName === 'Industry Report') {
      this.router.navigate(['/industryreport/open']);
    } else if (this.params.data.productName === 'Company Report') {
      this.router.navigate(['/companyreport/open']);
    } 
    else if (this.params.data.productName === 'Industry With Changes Report') {
      this.router.navigate(['/industryChangeReport/open']);
    } 
    else {
      this.router.navigate(['/flashnote/open']);
    }
  }

}
