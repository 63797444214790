import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_services';
import _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.scss']
})
export class DisclosureComponent implements OnInit {
  
  columnDefs = [
    {
      headerName: 'Company Name',
      field: 'issuerName',
      width: 540,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    }];
    gridApi: any;
    gridColumnApi: any;
    companyDef = [];
    selectedRow: any;
    rowSelection = 'single';
    valuationMethodology: any;
    investmentRisks: any
    imageSource: any;
    paginationPageSize = 20;
    gridStyle = {
      height: '420px',
      width: 'auto'
    };
    rowHeight = 25;
    currentYear: Number;
    currentDate: any;
    macroData: any;
  constructor(private apiService: ApiService, private sanitizer: DomSanitizer) { 

   }

  ngOnInit() {
   this.apiService.getIssuerData().subscribe(data =>{
     this.companyDef = data;
     this.setTableHeight()
   });
   this.currentYear = new Date().getFullYear();
   this.currentDate = new Date()
  }
  onGridReady(params) {
    
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    this.setTableHeight();
  }

  setTableHeight() {
    this.gridApi.sizeColumnsToFit();

    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight -250;
    /*Get the HOT length*/
    const hotData = this.companyDef;
    if (hotData.length > 0) {
      hotLength = hotData.length > this.paginationPageSize ? this.paginationPageSize : hotData.length;
    }
    
    const newHeight = ((hotLength) * this.rowHeight) + 200;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
      
    }
    this.paginationPageSize = Math.round(availableHeight/this.rowHeight) + 4 ;
    
    this.gridStyle.height = `${availableHeight}px`;
  }

  getRowNodeId({issuerID}) {
    return issuerID;
  }
  onSelectionChanged(){
     this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
     this.apiService.getIssuerDisclosuresDetails(this.selectedRow.issuerID).subscribe(data=>{
      if (data.success) {
        this.investmentRisks = data.investmentRisk;
        this.valuationMethodology = data.valuationMethod;
      }
    })
    
    if (this.selectedRow.ticker !='') {
      this.apiService.downloadChart(this.selectedRow.ticker).subscribe(data=>{
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data}`)
        // this.image = 'data:image/png;base64,' + data;
        // let objectURL = 'data:image/png;base64,' + data;
        // this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    })
    }
    this.apiService.getMacroData().subscribe(data =>{
        this.macroData = data;
    });
  }
  onChange(event){
    this.selectedRow = event;
    this.apiService.getIssuerDisclosuresDetails(event.issuerID).subscribe(data=>{
      if (data.success) {
        this.investmentRisks = data.investmentRisk;
        this.valuationMethodology = data.valuationMethod;
      }
    })
    
    if (this.selectedRow.ticker !='') {
      this.apiService.downloadChart(this.selectedRow.ticker).subscribe(data=>{
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${data}`)
    })
    }
    this.apiService.getMacroData().subscribe(data =>{
      this.macroData = data;
    });
  }
  
}
