import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AgGridModule} from 'ag-grid-angular';
import {NgSelectModule} from '@ng-select/ng-select';
import {QuillModule} from 'ngx-quill';

import {AppComponent} from './app.component';
import {routing} from './app.routing';

import {CardComponent, NgbdModalComponent} from './_directives';
import {AuthGuard} from './_guards';
import {JwtInterceptor} from './_helpers';
import {AlertService, AuthenticationService, DataService} from './_services';
import {AdminService} from './administration/admin.service';
import {HomeComponent} from './home';
import {LoginComponent} from './login';
import {ReportsComponent} from './reports';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {
  AdminComponent,
  DistributorAdminComponent,
  IndustryAdminComponent,
  IssuerAdminComponent,
  RatingsDistributionComponent,
  UserAdminComponent
} from './administration';
import {AllDocumentsComponent, CompanyreportComponent, IndustryreportComponent, MorningcallComponent, FlashnoteComponent, IndustryChangeReportComponent} from './documents';
import {NotFoundComponent} from './404/404.component';

import {
  ActionRendererComponent,
  CheckboxRendererComponent,
  CustomHeaderRendererComponent,
  CustomUnitRendererComponent,
  DocumentHeadlineRendererComponent,
  DocumentOpenRendererComponent
} from './renderers';

import {CustomNgbDateParserFormatter, PermissionFilterPipe, SafePipe, SectorFilterPipe} from './_pipes';
import { DisclosureComponent } from './disclosure/disclosure.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    NgbModule,
    NgxSpinnerModule,
    QuillModule.forRoot(),
    AgGridModule.withComponents([
      DocumentHeadlineRendererComponent,
      DocumentOpenRendererComponent,
      CheckboxRendererComponent,
      CustomUnitRendererComponent,
      CustomHeaderRendererComponent,
      ActionRendererComponent])
  ],
  declarations: [
    AppComponent,
    NgbdModalComponent,
    HomeComponent,
    MorningcallComponent,
    AdminComponent,
    UserAdminComponent,
    IssuerAdminComponent,
    IndustryAdminComponent,
    DistributorAdminComponent,
    RatingsDistributionComponent,
    LoginComponent,
    AllDocumentsComponent,
    CardComponent,
    CompanyreportComponent,
    FlashnoteComponent,
    IndustryreportComponent,
    IndustryChangeReportComponent,
    NotFoundComponent,
    DocumentHeadlineRendererComponent,
    DocumentOpenRendererComponent,
    CheckboxRendererComponent,
    CustomHeaderRendererComponent,
    CustomUnitRendererComponent,
    ActionRendererComponent,
    ReportsComponent,
    PermissionFilterPipe,
    SectorFilterPipe,
    SafePipe,
    DisclosureComponent
  ],
  entryComponents: [NgbdModalComponent],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    DataService,
    AdminService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate')}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
