import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'permissionFilter',
  pure: false
})
export class PermissionFilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => (item.issuerPermission &&
      item.issuerPermission.some(someitem => new RegExp(filter.toString(), 'ig').test(someitem.issuerName))) ||
      item.sectorName.toLowerCase().indexOf(filter.toString().toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'sectorFilter',
  pure: false
})
export class SectorFilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => new RegExp(filter.toString(), 'ig').test(item.issuerName));
  }
}
